/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import HorizontalScrollNavigation from './HowToUseHorizontalScrollNavigation';
import imgAppStore from './images/app-store.png';
import imgPlayStore from './images/play-store.png';
import styles from './SectionPlus3PayHowToUse.module.scss';
import { trackAppDownload } from '../../utils';

const data = {
  topTitle: (
    <>
      <p className={cx(styles.pc, styles.topTitle)}>
        使い方はとってもかんたん！
      </p>
      <p className={cx(styles.sp, styles.topTitle)}>
        使い方はとっても
        <br />
        かんたん！
      </p>
    </>
  ),
};

const items = [
  {
    image: () => (
      <>
        <div className={styles.pc}>
          <img
            src={require('./images/step-1.png')}
            alt={'ペイディアプリをダウンロードして、本人確認をする。'}
          />
        </div>
        <div className={styles.sp}>
          <div className={styles.containerImgStep1}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackAppDownload('App Store', 'plus_3pay_howto')}
            >
              <img
                className={styles.imgApp}
                src={imgAppStore}
                alt="app-store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackAppDownload('Google Play', 'plus_3pay_howto')}
            >
              <img
                className={styles.imgApp}
                src={imgPlayStore}
                alt="play-store"
              />
            </a>
          </div>
        </div>
      </>
    ),
    text: `ペイディアプリをダウンロードして、本人確認をする。`,
  },
  {
    image: () => (
      <img
        src={require('./images/step-2.svg')}
        alt={'ショップで欲しい商品をカートに入れる。'}
      />
    ),
    text: `ショップで欲しい商品をカートに入れる。`,
  },
  {
    image: () => (
      <img
        src={require('./images/step-3.svg')}
        alt={
          'お支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力する。'
        }
      />
    ),
    text: `お支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力する。`,
  },
  {
    image: () => (
      <img
        src={require('./images/step-4.svg')}
        alt={'お支払い回数で「３回あと払い」を選んで、お買い物完了！'}
      />
    ),
    text: `お支払い回数で「３回あと払い」を選んで、お買い物完了！`,
  },
];

const SectionPlus3PayHowToUse = () => {
  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <h2 className={styles.ttl}>{data.topTitle}</h2>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default SectionPlus3PayHowToUse;
