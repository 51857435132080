import React from 'react';
import { Grid, Container } from '@material-ui/core';
import imgTopBanner from './images/top-banner.png';
import imgTopBannerMobile from './images/top-banner-mobile.png';
import styles from './SectionPlus3PayTopBanner.module.scss';

const SectionPlus3PayTopBanner = () => (
  <section className={styles.container}>
    <Container>
      <Grid container>
        <Grid item className={styles.containerTexts} xs={12} md="auto">
          <h1 className={styles.title}>
            欲しいものがあるけど、
            <br />
            ちょっとだけ手が届かない…
          </h1>
          <p className={styles.timeText}>＼そんな時は！／</p>
          <div className={styles.containerTextBold}>
            <h1 className={styles.textBold}>ペイディの３回あと払い</h1>
            <h1 className={styles.textBold}>分割手数料無料*</h1>
          </div>
          <p className={styles.textDescription}>*口座振替・銀行振込のみ無料</p>
        </Grid>
        <Grid item className={styles.containerBanner}>
          <picture>
            <source media="(min-width: 1024px)" srcSet={imgTopBanner} />
            <img
              className={styles.banner}
              src={imgTopBannerMobile}
              alt="top-banner"
            />
          </picture>
        </Grid>
      </Grid>
    </Container>
  </section>
);

export default SectionPlus3PayTopBanner;
