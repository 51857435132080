import React from 'react';
import imgQr from './images/qr.png';
import imgAppStore from './images/app-store.png';
import imgPlayStore from './images/play-store.png';
import styles from './SectionPlus3PayQR.module.scss';
import { trackAppDownload } from '../../utils';

const SectionPlus3PayQR = () => (
  <section className={styles.container}>
    <img className={styles.qr} src={imgQr} alt="QR" />
    <div className={styles.containerRight}>
      <p className={styles.textDescription}>
        ペイディアプリをダウンロードして
        <br />
        今すぐ３回あと払いを使ってみる
      </p>
      <div className={styles.containerButtons}>
        <a
          href="https://apps.apple.com/jp/app/paidy/id1220373112"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackAppDownload('App Store', 'plus_3pay_qr')}
        >
          <img className={styles.buttonApp} src={imgAppStore} alt="app-store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
          target="_blank"
          rel="noreferrer"
          onClick={() => trackAppDownload('Google Play', 'plus_3pay_qr')}
        >
          <img
            className={styles.buttonApp}
            src={imgPlayStore}
            alt="play-store"
          />
        </a>
      </div>
    </div>
  </section>
);

export default SectionPlus3PayQR;
