/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import HorizontalScrollNavigation from './UpgradeHorizontalScrollNavigation';
import imgAppStore from './images/app-store.png';
import imgPlayStore from './images/play-store.png';
import styles from './SectionPlus3PayUpgrade.module.scss';
import { trackAppDownload } from '../../utils';

const data = {
  topTitle: (
    <>
      <p className={cx(styles.pc, styles.topTitle)}>
        本人確認は、たったの5分で完了。
      </p>
      <p className={cx(styles.sp, styles.topTitle)}>
        本人確認は、
        <br />
        たったの5分で完了。
      </p>
    </>
  ),
};

const items = [
  {
    image: () => (
      <>
        <div className={styles.pc}>
          <img
            src={require('./images/step-b-1.svg')}
            alt={'ペイディアプリをダウンロード。'}
          />
        </div>
        <div className={styles.sp}>
          <div className={styles.containerImgStep1}>
            <a
              href="https://apps.apple.com/jp/app/paidy/id1220373112"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackAppDownload('App Store', '3pay_upgrade')}
            >
              <img
                className={styles.imgApp}
                src={imgAppStore}
                alt="app-store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paidy.paidy"
              target="_blank"
              rel="noreferrer"
              onClick={() => trackAppDownload('Google Play', '3pay_upgrade')}
            >
              <img
                className={styles.imgApp}
                src={imgPlayStore}
                alt="play-store"
              />
            </a>
          </div>
        </div>
      </>
    ),
    text: `ペイディアプリをダウンロード。`,
  },
  {
    image: () => (
      <img
        src={require('./images/step-b-2.svg')}
        alt={'運転免許証またはマイナンバーカードを撮影。'}
      />
    ),
    text: `運転免許証またはマイナンバーカードを撮影。`,
  },
  {
    image: () => (
      <img src={require('./images/step-b-3.svg')} alt={'顔写真を撮影。'} />
    ),
    text: `顔写真を撮影。`,
  },
  {
    image: () => (
      <img
        src={require('./images/step-b-4.svg')}
        alt={
          '必要事項を記入して完了。お申し込みの結果はメールおよびSMSでご連絡します。'
        }
      />
    ),
    text: `必要事項を記入して完了。お申し込みの結果はメールおよびSMSでご連絡します。`,
  },
];

const SectionPlus3PayUpgrade = () => {
  return (
    <section className={styles.section}>
      <div className={styles.inner}>
        <h2 className={styles.ttl}>{data.topTitle}</h2>
      </div>
      <HorizontalScrollNavigation items={items} />
    </section>
  );
};

export default SectionPlus3PayUpgrade;
