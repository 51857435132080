import React from 'react';
import cx from 'classnames';
import { Grid, Button, Container } from '@material-ui/core';
import img1 from './images/paidy-card-1.png';
import img2 from './images/paidy-card-2.png';
import styles from './SectionPlus3PayWhatPaidyCanDo.module.scss';

const FEATURES = [
  {
    key: 1,
    src: img1,
    alt: 'app-1',
    titlePc: (
      <p>
        楽しみ方が広がる
        <br />
        ペイディカード
      </p>
    ),
    titleSp: (
      <p>
        楽しみ方が広がる
        <br />
        ペイディカード
      </p>
    ),

    descriptionPc: (
      <p>
        Visaが使えるオンラインショップやペイディアプリで、ペイディを利用してお買い物を楽しめます。
        <br />
        <br />
        <span className={styles.textSmall}>
          *「どこでもペイディ」は「ペイディカード」に名称変更しました
        </span>
      </p>
    ),
    descriptionSp: (
      <p>
        Visaが使えるオンラインショップやペイディアプリで、ペイディを利用してお買い物を楽しめます。
      </p>
    ),
  },
  {
    key: 2,
    src: img2,
    alt: 'app-2',
    titlePc: (
      <p>
        予算設定で <br />
        使い過ぎを防止
      </p>
    ),
    titleSp: <p>使い過ぎを防止</p>,
    descriptionPc: (
      <p>
        毎月の利用金額の予算を設定可能。予算を超えるとお知らせするので、使い過ぎを防止できます。
      </p>
    ),
    descriptionSp: (
      <p>
        予算設定機能や、いつでもすぐに確認できる利用履歴、利用通知メールなど、便利な機能で使い過ぎを防止。
      </p>
    ),
  },
];

const SectionPlus3PayWhatPaidyCanDo = () => (
  <section>
    <div className={styles.whiteBg}>
      <Container>
        <h1 className={styles.title}>
          本人確認をすると、ペイディがもっと便利に。
        </h1>
      </Container>
      <Container>
        <Grid
          container
          justify="center"
          spacing={4}
          className={styles.gridContainer}
        >
          {FEATURES.map(
            ({
              src,
              alt,
              titlePc,
              titleSp,
              descriptionPc,
              descriptionSp,
              key,
            }) => (
              <Grid
                key={key}
                item
                className={styles.gridItem}
                xs={12}
                md="auto"
              >
                <picture className={styles.picture}>
                  <img src={src} alt={alt} className={styles.imgFeature} />
                </picture>
                <div className={cx(styles.textTitle, styles.pc)}>{titlePc}</div>
                <div className={cx(styles.textTitle, styles.sp)}>{titleSp}</div>
                <div className={cx(styles.textDescription, styles.pc)}>
                  {descriptionPc}
                </div>
                <div className={cx(styles.textDescription, styles.sp)}>
                  {descriptionSp}
                </div>
              </Grid>
            )
          )}
        </Grid>
        <div className={styles.containerButton}>
          <Button
            className={styles.buttonPaidyPlus}
            color="primary"
            href="https://paidy.com/plus/"
            target="_blank"
            variant="contained"
          >
            もっと詳しく
          </Button>
        </div>
      </Container>
    </div>
  </section>
);

export default SectionPlus3PayWhatPaidyCanDo;
