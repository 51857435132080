import React from 'react';
import { Grid, Container } from '@material-ui/core';
import imgProduct1 from './images/product-1.png';
import imgProduct2 from './images/product-2.png';
import imgProduct2Mobile from './images/product-2-mobile.png';
import styles from './SectionPlus3PayProducts.module.scss';

const SectionPlus3PayProducts = () => (
  <section className={styles.container}>
    <Container>
      <Grid container justify="space-between">
        <Grid item xs={12} md="auto" className={styles.gridItem}>
          <p className={styles.headline}>例えば、</p>
          <p className={styles.textLeft}>30,000円の商品なら…</p>
          <img
            className={styles.imgProduct1}
            src={imgProduct1}
            alt="product-1"
          />
        </Grid>
        <Grid item xs={12} md="auto" className={styles.gridItem}>
          <p className={styles.textRight}>
            10,000円ずつ、
            <br />
            ３か月でお支払い！
          </p>
          <picture>
            <source media="(min-width: 1024px)" srcSet={imgProduct2} />
            <img
              className={styles.imgProduct2}
              src={imgProduct2Mobile}
              alt="product-2"
            />
          </picture>

          <p className={styles.textDescription}>
            ちょっと手の届かない商品も、かしこく計画的にGET。
          </p>
        </Grid>
      </Grid>
    </Container>
  </section>
);

export default SectionPlus3PayProducts;
