import React from 'react';
import { SITE_METADATA } from '../../../constants';
import Layout from '../../../components/Layout/Layout';
import SectionPlus3PayTopBanner from '../../../components/Plus3Pay/SectionPlus3PayTopBanner';
import SectionPlus3PayProducts from '../../../components/Plus3Pay/SectionPlus3PayProducts';
import SectionPlus3PayHowToUse from '../../../components/Plus3Pay/SectionPlus3PayHowToUse';
import SectionPlus3PayUpgrade from '../../../components/Plus3Pay/SectionPlus3PayUpgrade';
import SectionPlus3PayQR from '../../../components/Plus3Pay/SectionPlus3PayQR';
import SectionPlus3PayWhatPaidyCanDo from '../../../components/Plus3Pay/SectionPlus3PayWhatPaidyCanDo';
import SectionPlus3PayFooter from '../../../components/Plus3Pay/SectionPlus3PayFooter';

const Plus3Pay = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.plus3Pay.title,
        description: SITE_METADATA.plus3Pay.description,
      }}
      hasNoHeader
      hasNoFooter
    >
      <SectionPlus3PayTopBanner />
      <SectionPlus3PayProducts />
      <SectionPlus3PayHowToUse />
      <SectionPlus3PayUpgrade />
      <SectionPlus3PayQR />
      <SectionPlus3PayWhatPaidyCanDo />
      <SectionPlus3PayFooter />
    </Layout>
  );
};

export default Plus3Pay;
