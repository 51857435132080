import React from 'react';
import cx from 'classnames';
import LazyLoad from 'react-lazyload';
import useHorizontalScrollNavigation from '../../hooks/useHorizontalScrollNavigation';
import styles from './UpgradeHorizontalScrollNavigation.module.scss';

const ScrollItem = ({ ImgComponent, figCaption }) => {
  return (
    <figure className={styles.howToRowItem}>
      <div className={styles.howToRowItemFigure}>
        <LazyLoad height={184} offset={1000} once>
          <ImgComponent />
        </LazyLoad>
      </div>
      <figcaption className={styles.featureRowItemCaption}>
        {figCaption}
      </figcaption>
    </figure>
  );
};

const HorizontalScrollNavigation = ({ items }) => {
  const {
    ref,
    navigationLength,
    focusedIndex,
    isOverflow,
  } = useHorizontalScrollNavigation({
    contentClassName: styles.howToRowItem,
  });

  return (
    <div className={styles.bgContainer}>
      <div
        className={styles.container}
        style={{
          width: isOverflow ? '100%' : undefined,
        }}
      >
        <div className={styles.horizontalScrollContainer}>
          <div className={styles.horizontalScrollInner}>
            <div className={styles.ordersMiddleLine} />
            <ol className={styles.orderItemContainer}>
              {items.map((_, index) => (
                <li className={styles.orderItem}>
                  <span className={styles.orderBase}>
                    <span className={styles.orderInner}>
                      <span className={styles.order}>{index + 1}</span>
                    </span>
                  </span>
                </li>
              ))}
            </ol>
          </div>
          <div className={styles.horizontalScrollInner} ref={ref}>
            {items.map((item, index) => (
              <React.Fragment>
                {index > 0 ? (
                  <img
                    src={require('../../images/paidycard-right-arrow.svg')}
                    alt={'right-arrow'}
                    width={32}
                    height={32}
                    className={cx(styles.howToRowRightArrow, styles.sp)}
                  />
                ) : null}
                <ScrollItem ImgComponent={item.image} figCaption={item.text} />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.navigationContainer}>
          {[...new Array(navigationLength).fill(null)].map((_, index) => (
            <div
              className={styles.navigationDot}
              style={{
                opacity: index === focusedIndex ? 1 : 0.3,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollNavigation;
